// If not Teams SSO authetificated

export const NotInTeams: React.FC = () =>{
  return (
    <div className="App">
      <header className="App-header">
        <p className="loading">
          Sign in
        </p>
      </header>
    </div>
  )
}


