import { useState, useEffect } from "react";
import { useTeams } from "msteams-react-base-component";
import * as microsoftTeams from "@microsoft/teams-js";
import './App.css';
import { NotInTeams } from './NotInTeams';
import {CcTable} from './CcTable'

function App() {
    const [{ inTeams }] = useTeams();
// set teams SSO status
    const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
      if (inTeams === true) {
        microsoftTeams.authentication.getAuthToken({
            successCallback: () => {
                setIsSignedIn(true);
                microsoftTeams.appInitialization.notifySuccess();
            },
            failureCallback: () => {
                setIsSignedIn(false);
                microsoftTeams.appInitialization.notifyFailure({
                    reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
                });

            },
            resources: [process.env.TAB_APP_URI as string]
        });
      } else {
          setIsSignedIn(false)
      }
  }, [inTeams]);

  if (isSignedIn) {
    return (
      <CcTable />
    )
  }
  return (
    <NotInTeams/>
  );
}

export default App;
