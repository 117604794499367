// If Teams SSO authetificated

import MaterialTable from "material-table";
import ContactsList from "./contactslist";

export const CcTable: React.FC = () =>{
    return (
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          detailPanel={(rowData) => {
            const MobilePhone = () => {
              if (rowData.mobilePhone) {
                return (
                  <div className="detailphone">
                    <div className="detailheader">Mob:</div>
                    <div className="detailvalues">
                      {rowData.mobilePhone}
                    </div>
                    <div>
                      <a
                        href={"tel:" + rowData.mobilePhone}
                      >
                        <button className="detailbutton">Call</button>
                      </a>
                    </div>
                  </div>
                );
              } else return <> </>;
            };
            const BusinessPhone = () => {
              if (rowData.businessPhones && rowData.businessPhones[0]) {
                return (
                  <div className="detailphone">
                    <div className="detailheader">Business:</div>
                    <div className="detailvalues">
                      {rowData.businessPhones[0]}
                    </div>
                    <div>
                      <a
                        href={"tel:" + rowData.businessPhones[0]}
                      >
                        <button className="detailbutton">Call</button>
                      </a>
                    </div>
                  </div>
                );
              } else return <> </>;
            };
            const BusinessPhone2 = () => {
              if (rowData.businessPhones && rowData.businessPhones[1]) {
                return (
                  <div className="detailphone">
                    <div className="detailheader">Business 2:</div>
                    <div className="detailvalues">
                      {rowData.businessPhones[1]}
                    </div>
                    <div>
                      <a
                        href={"tel:" + rowData.businessPhones[1]}
                      >
                        <button className="detailbutton">Call</button>
                      </a>
                    </div>
                  </div>
                );
              } else return <> </>;
            };
            const Email = () => {
              const expression = /\S+@\S+/;
              if (
                rowData.mail && expression.test(String(rowData.mail[0]).toLowerCase())
              ) {
                return (
                  <div className="detailphone">
                    <div className="detailheader">E-mail:</div>
                    <div className="detailvalues">
                      {rowData.mail[0]}
                    </div>
                    <div>
                      <a
                        href={"mailto:" + rowData.mail[0]}
                      >
                        <button className="detailbutton">Send</button>
                      </a>
                    </div>
                  </div>
                );
              } else return <> </>;
            };
            const Email2 = () => {
              const expression = /\S+@\S+/;
              if (
                rowData.mail && rowData.mail[1] && expression.test(String(rowData.mail[1]).toLowerCase())
              ) {
                return (
                  <div className="detailphone">
                    <div className="detailheader">E-mail 2:</div>
                    <div className="detailvalues">
                      {rowData.mail[1]}
                    </div>
                    <div>
                      <a
                        href={"mailto:" + rowData.mail[1]}
                      >
                        <button className="detailbutton">Send</button>
                      </a>
                    </div>
                  </div>
                );
              } else return <> </>;
            };
            return (
              <div className="detaillist">
                <MobilePhone />
                <BusinessPhone />
                <BusinessPhone2 />
                <Email />
                <Email2 />
              </div>
            );
          }}
          columns={[
            { title: "First Name", field: "givenName" },
            { title: "Last Name", field: "surname" },
            { title: "Company", field: "companyName" },
            { title: "Job title", field: "jobTitle" },
            { title: "Country", field: "country" },
          ]}
          data={ContactsList}
          title="Belam Corporate Contacts"
          options={{
            filtering: true,
            grouping: true,
            pageSize: 5,
            headerStyle: {
              backgroundColor: "#919191",
              color: "#FFF",
              fontSize: "100%",
            },
          }}
        />
      </div>
    );
  };